import * as React from "react";
import Layout from "../components/layout"
import aboundImg from "../images/abound-specs.jpg";
import aboundVideo from "../images/abound_video.mp4"
import { pageStyles } from "../styles/helpers";
import "../styles/global-styles.css";

// styles
const imageStyles = {
  marginBottom: 0,
  width: "100%",
};
const videoStyles = {
  marginTop: "-4px",
  marginBottom: 0,
  width: "100%",
};

// markup
const pace500 = () => {
  return (
    <main style={pageStyles}>
      <title>Pace 500 Specs</title>
      <img style={imageStyles} src={aboundImg} />
      <video style={videoStyles} crossOrigin="anonymous" controls={false} autoPlay loop muted>
        <source src={aboundVideo} type="video/mp4" />
      </video>
    </main>
  );
};

export default pace500;
